import { Observable } from 'rxjs';
import { Globals } from './../../globals';

export interface User {
  name: string;
  picture: string;
}

export interface Contacts {
  user: User;
  type: string;
}

export interface RecentUsers extends Contacts {
  time: number;
}

export abstract class UserData {
  public objData: any;

  constructor(private global: Globals) {

    this.objData = this.global.decryptObject(localStorage.getItem('user_data'));
  }
  abstract getUsers(): Observable<User[]>;
  abstract getContacts(): Observable<Contacts[]>;
  abstract getRecentUsers(): Observable<RecentUsers[]>;
}
