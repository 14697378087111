import { Component, OnDestroy, OnInit, ChangeDetectorRef, Input, DoCheck, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Globals, ROLES } from '../../../globals';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy, AfterContentChecked {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  defaultImage = 'assets/images/user-profile.jpg';
  userName;
  userImage;

  // ROLE & PERMISSION
  userType = ROLES;
  permission;
  public role = localStorage.getItem('sidebarOption');

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile', link: '/pages/user/profile' }, { title: 'Change Password', link: '/pages/change-password' }, { title: 'Log out', link: '/logout' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private global: Globals,
    public router: Router,
  ) {
  }

  ngOnInit() {
    this.permission = this.userType[this.role];
    this.currentTheme = this.themeService.currentTheme;
    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => {
    //     this.user = users.nick;
    //   });
    this.userService.getUsers()
      .subscribe((users: any) => {
        // this.user = users.nick;
        // console.log('USER::::', this.user);
        this.user = this.global.decryptObject(localStorage.getItem('user_data'));
        this.userName = this.user.first_name + ' ' + this.user.last_name;
        this.userImage = this.user.image ? this.user.image : this.defaultImage;
      });
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngAfterContentChecked() {
    this.userService.getUsers()
      .subscribe((users: any) => {
        // this.user = users.nick;
        // console.log('USER::::', this.user);
        this.user = this.global.decryptObject(localStorage.getItem('user_data'));
        this.userName = this.user.first_name + ' ' + this.user.last_name;
        this.userImage = this.user.image ? this.user.image : this.defaultImage;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    if (this.permission !== 'Player' && this.permission !== 'Tutor') {
      this.router.navigate(['/dashboard']);
    }
  }
}
