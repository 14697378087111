import { Component, OnInit } from '@angular/core';
declare var $: any;

const theDate = new Date();
let year = theDate.getFullYear();
@Component({
	selector: 'ngx-footer',
	styleUrls: ['./footer.component.scss'],
	templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
	todayDate: Date = new Date();

	ngOnInit() {
		// this.loadJquery();
	}

	// loadJquery() {
	// 	// Start Back to Top Js
	// 	$(window).scroll(function () {
	// 		var height = $(window).scrollTop();
	// 		if (height > 100) {
	// 			$('#back_to_top').fadeIn();
	// 		} else {
	// 			$('#back_to_top').fadeOut();
	// 		}
	// 	});
	// 	$(document).ready(function () {
	// 		$("#back_to_top").click(function (event) {
	// 			event.preventDefault();
	// 			$("html, body").animate({
	// 				scrollTop: 0
	// 			}, "slow");
	// 			return false;
	// 		});

	// 	});
	// 	// Close Back to Top Js
	// }

}
