import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EncrDecrService } from './EncrDecr/encr-decr.service';
import SimpleCrypto from 'simple-crypto-js';

@Injectable({
  providedIn: 'root',
})
export class Globals {

  public secretKey = 'nero_lac!1@2#3';
  public simpleCrypto = new SimpleCrypto(this.secretKey);

  constructor(private router: Router, private encrDecr: EncrDecrService) { }

  logOutGlobal() {

    localStorage.clear();
    this.router.navigate(['/']);
  }

  /**
   * [encryptObject description]
   * @param   {[type]} data
   */
  encryptObject(data) {

    const encrypted = this.simpleCrypto.encrypt(data);
    return encrypted;
  }

  /**
 * [decryptObject description]
 */
  decryptObject(data) {

    try {
      const decrypted = this.simpleCrypto.decrypt(data, true);
      return decrypted;
    } catch (error) {
      this.redirectToLogin();
    }

    // const decrypted = this.simpleCrypto.decrypt(data, true);
    // return decrypted;
  }

  redirectToLogin = () => {
    localStorage.removeItem('user_data');
    this.router.navigate(['/']);
  }

}


export const FILTER_LIMIT = [
  {
    key: '25',
    value: 25,
  },
  {
    key: '50',
    value: 50,
  },
  {
    key: '100',
    value: 100,
  },
  {
    key: '200',
    value: 200,
  },
];

export const USER_ROLES_DROPDOWN = [
  {
    key: 1,
    value: 'Admin',
  },
  {
    key: 2,
    value: 'Manager',
  },
  {
    key: 3,
    value: 'Builder',
  },
  {
    key: 4,
    value: 'Tutor',
  },
  {
    key: 0,
    value: 'Player',
  },
  // {
  //   key: 5,
  //   value: 'Player',
  // },
];

export const ROLES = [
  'Player',
  'Admin',
  'Manager',
  'Builder',
  'Tutor',
  // 'Player',
];

export const USER_STATUS = [
  {
    key: 1,
    value: 'Activate',
  },
  {
    key: 2,
    value: 'Deactivate',
  },
  {
    key: 3,
    value: 'Delete',
  },
];

export const GRADE_LEVEL = [
  {
    key: 1,
    value: 'Beginner',
  },
  {
    key: 2,
    value: 'Intermediate',
  },
  {
    key: 3,
    value: 'Advance',
  },
];

export const SUBSCRIPTION_TYPE = [
  {
    key: 1,
    value: 'Trial',
  },
  {
    key: 3,
    value: 'Monthly',
  },
  {
    key: 4,
    value: 'Quarterly',
  },
  {
    key: 2,
    value: 'Annual',
  },
  // {
  //   key: 5,
  //   value: 'Other',
  // },
];

